import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getImage } from 'gatsby-plugin-image'

import { trackProductViewEvent } from 'src/utils/event-tracking'
import ImageCarousel from './ImageCarousel'
import CtaButtons from './CtaButtons'
import ProductSelector from 'src/components/PDP/AboveTheFold/ProductSelector'
import TotalReviewsAndRating from 'src/components/PDP/TotalReviewsAndRating'
import AfterpayModal from 'src/components/shared/AfterpayModal'
import Spinner from 'src/components/shared/Spinner'
import { Headline, P, MonoP } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'
import { SUBSCRIPTION_FREQUENCIES } from 'src/utils/globalVariables'
import { addVariantToCart } from 'src/actions/checkout'
import { trackAddToCartEvent, updateProductViewEvent } from 'src/utils/event-tracking'
import { IAboveTheFoldProps } from 'src/types/pdp'
import { IProductDataInterface } from 'src/types/product-actions'
import useCart from 'src/hooks/useCart'

import DodoLogoNavy from 'src/assets/img/press-logos/dodo-navy.svg'
import ForbesLogoNavy from 'src/assets/img/press-logos/forbes-navy.svg'
import TravelLeisureLogoNavy from 'src/assets/img/press-logos/travel-leisure-navy.svg'
import GreatPetNavy from 'src/assets/img/press-logos/great-pet-navy.svg'

import Burn from 'src/data/products/images/puppy-breath-candle/icons/fire.svg'
import Brush from 'src/data/products/images/puppy-breath-candle/icons/brush.svg'
import Moon from 'src/data/products/images/puppy-breath-candle/icons/cresent.svg'
import Puppy from 'src/data/products/images/puppy-breath-candle/icons/dog_puppy.svg'
import Dots from 'src/data/products/images/puppy-breath-candle/icons/dots.svg'
import Heart from 'src/data/products/images/puppy-breath-candle/icons/heart.svg'
import { edgeTagProductView } from 'src/utils/edgetag-tracking'

const Section = styled.section`
  position: relative;

  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  background-color: ${colors.white};
  height: fit-content;

  ${media.mobile`
    padding: 75px 0px 36px;
  `}
  ${media.tablet`
    padding: 100px 0 0;
  `}
  ${media.desktop`
    padding: 90px 0 0;
  `}
`

const Container = styled.div`
  width: 100%;
  height: fit-content;
  margin: 0 auto;

  ${media.tablet`
    padding: 0px 20px 40px 0;
    max-width: 900px;
  `}
  ${media.desktop`
    padding: 120px 0 66px;
    max-width: 1400px;
  `}
`

const Grid = styled.div`
  width: 100%;

  display: grid;
  align-items: start;
  justify-items: center;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  `}
  ${media.tablet`
    grid-template-columns: 0.9fr 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    margin-top: 0px;
  `}
`

const CopyContainer = styled(Container)`
  width: 100%;

  ${media.mobile`
    align-items: center;
    text-align: center;
  `}
  ${media.tablet`
    padding: 10px 0 0 0;
    align-items: flex-start;
    text-align: left;
  `}

  ${media.desktop`
    padding: 40px 0 0 0;
  `}

  & a {
    width: fit-content;
  }
`

const ProductName = styled(Headline)<{ longTitle: boolean }>`
  color: ${colors.navy};
  ${media.mobile`
    font-size: ${(props: { longTitle: boolean }) => (props.longTitle ? 40 : 55)}px;
    margin-bottom: 9px;
  `}
  ${media.tablet`
    font-size: ${(props: { longTitle: boolean }) => (props.longTitle ? 55 : 64)}px;
    margin-top: 0;
    margin-bottom: 10px;
  `}
  ${media.desktop`
    font-size: ${(props: { longTitle: boolean }) => (props.longTitle ? 55 : 64)}px;
  `}
`

const Description = styled(P)`
  width: 100%;

  white-space: pre-line;
  line-height: 1.3;
  margin: 0 auto;
  color: ${colors.navy};

  ${media.mobile`
    padding: 10px 10px 20px 10px;
    max-width: 350px;
    margin: 0 auto;
  `}
  ${media.tablet`
    padding: 10px 0 20px 0;
    max-width: 490px;
    margin: 0;
  `}

  ${media.desktop`
    font-size: 19px;
  `}

  .candle {
    ${media.mobile`
      font-size: 18px;
      text-align: left;
    `}
    ${media.desktop`
      font-size: 22px;
    `}
  }
`

const ChewCount = styled(MonoP)`
  font-size: 16px;
  color: ${colors.slate};
  ${media.mobile`
    margin: 30px 0 10px 0;
  `}
  ${media.tablet`
    margin: 16px 0 12px 2px;
  `}
`

const MobileOnly = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  ${media.tablet`
    display: none;
  `}
  &:first-of-type {
    padding: 0;
  }
`

const LargeScreenOnly = styled.div`
  width: 100%;

  ${media.mobile`
    display: none;

  `}
  ${media.tablet`
    display: block;
  `}

  & a {
    width: fit-content;
  }
`

const SubText = styled(P)`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  color: ${colors.navy};
  margin-bottom: 23px;
`

const AsSeen = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px 0;

  ${media.mobile`
    margin-bottom: 30px;
  `}
  ${media.tablet`
    margin-bottom: 30px;
  `}

  ${media.desktop`
    margin-bottom: 0px;
  `}

  .wrapper {
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    ${media.mobile`
      flex-direction: column;
    `}
    ${media.tablet`
      flex-direction: column;
    `}

    ${media.desktop`
      flex-direction: row;
    `}

    .logos {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
      width: 100%;
      gap: 20px;
      img {
        width: 100%;
        flex: 1;
        object-fit: contain;

        ${media.mobile`
          height: 15px;
        `}
        ${media.tablet`
          height: 32px;
        `}

        ${media.desktop`
          height: 32px;
        `}
      }
    }
  }
`

const ProductInfo = styled.div`
  max-width: 500px;

  ${media.mobile`
    padding: 20px 20px 0;
  `}

  ${media.desktop`
    padding: 40px 0 0;
  `}

  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;

    dt {
      border-bottom: 1px solid #999;
      grid-area: 1 / span 2;
      margin-bottom: 20px;
      padding-bottom: 10px;
      text-align: left;
    }

    dd {
      display: flex;
      align-items: baseline;
      gap: 10px;

      img {
        flex-shrink: 1;
        width: 20px;
        height: 20px;
        object-fit: contain;
        transform: translateY(2px);
      }

      ${media.mobile`
        grid-column: span 2;
      `}

      ${media.desktop`
        grid-column: span 1;
      `}
    }
  }
`

const Spacer = styled.div`
  padding-bottom: 66px;
`

const AboveTheFold: React.FC<IAboveTheFoldProps> = ({ reviewStats, data }) => {
  let {
    title,
    slug,
    shortDescription,
    isBundle,
    colors: brandColors,
    carouselImages,
    outOfStock,
    category
  } = data

  const { loading, checkoutId, setStore, products, checkout, actualTotal } = useCart()

  const productsBySlug = products?.bySlug

  const [activeVariant, setActiveVariant] = useState<IProductDataInterface | null>(null)
  const [frequency, setFrequency] = useState(
    slug === 'pumpkin-plus' || slug === 'bone-broth-plus' ? '15' : SUBSCRIPTION_FREQUENCIES[2]
  )
  const [afterpayModalOpen, setAfterpayModalOpen] = useState(false)

  const firstCarouselImg = carouselImages[0].src
  const productImageSrc = getImage(firstCarouselImg)?.images?.fallback?.src
  const productImagePath = productImageSrc ? `https://www.petfinn.com${productImageSrc}` : ''

  slug = slug === 'allergyanditch' ? 'allergy-and-itch' : slug

  const product = productsBySlug ? productsBySlug[slug] : null
  const hasSubscriptionVariant = product?.single?.pricing?.subscription?.price ?? false
  const initialSubscriptionState = hasSubscriptionVariant

  const [isSubscription, setIsSubscription] = useState(Boolean(initialSubscriptionState))
  const priceType = isSubscription ? 'subscription' : 'oneTime'
  const variant = activeVariant?.pricing[priceType]
  const activeVariantPrice = variant?.price || 0

  useEffect(() => {
    if (!loading) {
      // If product has a 1-pack variant, select by default
      let newActiveVariant = product?.single || product?.multipack_2
      setActiveVariant(newActiveVariant)
      trackProductViewEvent(products, slug, productImagePath, newActiveVariant.variantId.decoded)
      edgeTagProductView(products, newActiveVariant.variantId.decoded)
      setIsSubscription(Boolean(product?.single?.pricing?.subscription?.price))
    }
  }, [loading, slug])

  const handleAddToCart = () => {
    const activeVariantId = activeVariant?.variantId.encoded

    if (!loading && checkoutId && activeVariantId) {
      const selectedFrequency = isSubscription ? frequency : 0
      const subscription = isSubscription ? activeVariant.pricing.subscription : null

      const detail = {
        checkout,
        productMap: products,
        variantId: activeVariantId,
        quantity: 1,
        frequency: selectedFrequency,
        setStore,
        subscription,
        actualTotal,
        unitPrice: Number(activeVariantPrice)
      }
      addVariantToCart(detail)
      trackAddToCartEvent(
        products,
        slug,
        activeVariant?.variantId.decoded,
        Number(activeVariantPrice),
        selectedFrequency
      )
    }
  }

  const handleProductViewEvent = (viewedVariant) => {
    updateProductViewEvent(
      products,
      slug,
      viewedVariant.variantId.decoded,
      viewedVariant.price,
      productImagePath
    )
  }

  const clickQuantityButton = (packType: any) => {
    const selectedProduct = product[packType]
    setIsSubscription(false)
    setActiveVariant(selectedProduct)
    handleProductViewEvent(selectedProduct)
  }

  const handleRadioClick = (sub: boolean) => {
    setIsSubscription(sub)
    if (sub) setActiveVariant(product.single)
  }

  const longTitle = title.length > 20

  return (
    <>
      <Section>
        {slug === 'puppy-breath-candle' && (
          <AsSeen>
            <div className="wrapper">
              <MonoP>AS SEEN IN</MonoP>
              <div className="logos">
                <img src={ForbesLogoNavy} />
                <img src={GreatPetNavy} />
                <img src={TravelLeisureLogoNavy} />
                <img src={DodoLogoNavy} />
              </div>
            </div>
          </AsSeen>
        )}
        <Container>
          <Grid>
            <MobileOnly>
              <CopyContainer>
                <ProductName longTitle={longTitle}>{title}</ProductName>
                {slug == 'paw-hero' && <SubText>lick-safe paw balm</SubText>}
                {(slug === 'pumpkin-plus' || slug === 'bone-broth-plus') && (
                  <SubText>Superfood meal topper</SubText>
                )}
                {slug === 'skin-hero' && <SubText>Super soothing skin balm</SubText>}
                <a href="#reviews">
                  <TotalReviewsAndRating reviewStats={reviewStats} />
                </a>
                <br />
              </CopyContainer>
            </MobileOnly>

            <ImageCarousel
              carouselImages={carouselImages}
              largeSlider={
                slug === 'paw-hero' ||
                slug === 'puppy-breath-candle' ||
                slug === 'pumpkin-plus' ||
                slug === 'bone-broth-plus'
              }
            />

            <CopyContainer>
              {loading && (
                <Spinner overlay={colors.white} color={colors[brandColors.bg]} size={80} />
              )}
              <LargeScreenOnly>
                <ProductName longTitle={longTitle}>{title}</ProductName>
                {slug == 'paw-hero' && <SubText>lick-safe paw balm</SubText>}
                {(slug === 'pumpkin-plus' || slug === 'bone-broth-plus') && (
                  <SubText>Superfood meal topper</SubText>
                )}
                {slug === 'skin-hero' && <SubText>Super soothing skin balm</SubText>}
                <a href="#reviews">
                  <TotalReviewsAndRating reviewStats={reviewStats} />
                </a>
              </LargeScreenOnly>
              {slug === 'paw-hero' || slug === 'skin-hero' ? (
                <ChewCount>50G / 1.75OZ</ChewCount>
              ) : (
                category !== 'powders' && (
                  <ChewCount>90 Soft Chews {isBundle && 'Per Tin'}</ChewCount>
                )
              )}
              <Description>
                {slug === 'puppy-breath-candle' ? (
                  <div className="candle">
                    <span dangerouslySetInnerHTML={{ __html: shortDescription }} />
                  </div>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: shortDescription }} />
                )}
              </Description>

              {!loading && activeVariant && hasSubscriptionVariant && (
                <ProductSelector
                  productOptions={productsBySlug[slug]}
                  activeVariant={activeVariant}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  brandColors={brandColors}
                  isBundle={isBundle}
                  isSubscription={isSubscription}
                  clickQuantityButton={clickQuantityButton}
                  handleRadioClick={handleRadioClick}
                  slug={slug}
                />
              )}

              {!loading && (
                <LargeScreenOnly style={{ marginTop: 18, marginBottom: 18 }}>
                  <CtaButtons
                    brandColors={brandColors}
                    price={activeVariantPrice}
                    outOfStock={outOfStock}
                    handleAddToCart={handleAddToCart}
                    hasSubscriptionVariant={hasSubscriptionVariant}
                    isSubscription={isSubscription}
                    setAfterpayModalOpen={setAfterpayModalOpen}
                    slug={slug}
                  />

                  {slug === 'puppy-breath-candle' && (
                    <ProductInfo>
                      <dl>
                        <dt>
                          <MonoP>Product Details</MonoP>
                        </dt>
                        <dd>
                          <img src={Burn} />
                          <P>40 hour burn time</P>
                        </dd>
                        <dd>
                          <img src={Heart} />
                          <P>Vegan & cruelty free</P>
                        </dd>
                        <dd>
                          <img src={Moon} />
                          <P>100% soy wax / 10.6 oz</P>
                        </dd>
                        <dd>
                          <img src={Brush} />
                          <P>Hand poured cement vessel</P>
                        </dd>
                        <dd>
                          <img src={Dots} />
                          <P>3 lead-free cotton wicks</P>
                        </dd>
                        <dd>
                          <img src={Puppy} />
                          <P>Natural fragrance that's safe for dogs</P>
                        </dd>
                      </dl>
                    </ProductInfo>
                  )}
                </LargeScreenOnly>
              )}
            </CopyContainer>
          </Grid>
        </Container>

        {!loading && (
          <MobileOnly>
            <CtaButtons
              brandColors={brandColors}
              price={activeVariantPrice}
              outOfStock={outOfStock}
              handleAddToCart={handleAddToCart}
              hasSubscriptionVariant={hasSubscriptionVariant}
              isSubscription={isSubscription}
              setAfterpayModalOpen={setAfterpayModalOpen}
              slug={slug}
            />

            {slug === 'puppy-breath-candle' && (
              <ProductInfo>
                <dl>
                  <dt>
                    <MonoP>Product Details</MonoP>
                  </dt>
                  <dd>
                    <img src={Burn} />
                    <P>40 hour burn time</P>
                  </dd>
                  <dd>
                    <img src={Heart} />
                    <P>Vegan & cruelty free</P>
                  </dd>
                  <dd>
                    <img src={Moon} />
                    <P>100% soy wax / 10.6 oz</P>
                  </dd>
                  <dd>
                    <img src={Brush} />
                    <P>Hand poured cement vessel</P>
                  </dd>
                  <dd>
                    <img src={Dots} />
                    <P>3 lead-free cotton wicks</P>
                  </dd>
                  <dd>
                    <img src={Puppy} />
                    <P>Natural fragrance that's safe for dogs</P>
                  </dd>
                </dl>
              </ProductInfo>
            )}
          </MobileOnly>
        )}
        {slug !== 'puppy-breath-candle' && slug !== 'paw-hero' && <Spacer />}
      </Section>

      {/* <AfterpayModal open={afterpayModalOpen} setOpen={setAfterpayModalOpen} /> */}
    </>
  )
}

export default AboveTheFold
